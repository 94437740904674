import React from 'react';
import { Helmet } from 'react-helmet';


function Demo()  {



  return (<React.Fragment>

    <Helmet>
      <meta charset="utf-8"></meta>
      <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
   </Helmet>


   <h2>Demo Content</h2>;



  
  </React.Fragment>) 


};

export default Demo;
